import React from "react";
import page1 from '../../media/malariapage1.png'

export default function MalariaPage(){
    return(
        <>
        <div style={{height:"10vh"}}></div>
        <img src={page1} style={{width:"100%"}}></img>
        </>
    )
}
import React from "react";
import page1 from '../../media/dengueday1page1.png'

export default function DengueDay1Page(){
    return(
        <>
        <div style={{height:'10vh'}}></div>
        <img src={page1} style={{width:'100%'}}></img>
        </>
    )
}
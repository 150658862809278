import React from "react";
import "./downloads.css"
import ComingSoon from "./coming";

export default function Downloadpage(){
    return(
        <>
        <div className="headerblock"></div>
        <ComingSoon />
        </>
    )
}